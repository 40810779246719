import React, { useState } from "react";

const CatalogSer = ({service, activeId, setActiveId, onServiceClick}) => {
  const handleClick = (id) => {
    setActiveId(id);
    onServiceClick(id);
  };

  return (
    <li
      className={activeId === service.id ? "service-item active" : "service-item"}
      data-service-id={service.id}
      onClick={() => handleClick(service.id)}
    >
      {service.title}
    </li>
  );
};

export default CatalogSer;