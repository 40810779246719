import React from "react";
import {useState, useEffect} from "react";


const CatalogListMob = ({categories, onServiceClick}) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState(7916838);
    const [selectedCategoryServicesData, setSelectedCategoryServicesData] = useState();

    const handleCategoryChange = (event) => {
        const categoryId = event.target.value;
        setSelectedCategoryId(categoryId);
        fetchData(categoryId);
        // onServiceClick(undefined);
    };

    const handleServiceChange = (event) => {
        const serviceId = event.target.value;
        onServiceClick(serviceId);
    };

    //Тут мы напрямую в fetch отправляем categoryId, который мы получили из handleCategoryChange, чтобы корректно получить данные (была проблема в том что отправлялось предыдущее значение стейта)
    const fetchData = async (categoryId) => {
        try {
            const response = await fetch(`https://anvilis.ru/cryon/TESTS/get_services_of_category.php?category_id=${categoryId}`);
            const data = await response.json();
            setSelectedCategoryServicesData(data); // Обновление состояния с полученными данными
            if (data.length > 0) {
                onServiceClick(data[0].id);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Вызов функции для получения данных при монтировании компонента
    }, []); // Пустой массив зависимостей гарантирует, что эффект выполняется только один раз при монтировании компонента


    return (
        <>
            <div className="catalog_navigation_mobile">
                <select id="category-select" onChange={handleCategoryChange}>
                    <option value="" selected disabled hidden>Выберите категорию</option>
                    {/* Render the categories */
                        categories.map((serviceCategory) => (
                            <option key={serviceCategory.id} value={serviceCategory.id}
                                    data-detailPage={serviceCategory.serviceDetails}>{serviceCategory.title}</option>
                        ))
                    }
                </select>

                <select onChange={handleServiceChange}>
                    <option value="" selected disabled hidden>Выберите сервис</option>
                    {/* Render the categories */
                        selectedCategoryServicesData && selectedCategoryServicesData.map((categoryServicesList) => (
                            <option key={categoryServicesList.id}
                                    value={categoryServicesList.id}>{categoryServicesList.title}</option>
                        ))
                    }
                </select>
            </div>
        </>
    );
};

export default CatalogListMob;