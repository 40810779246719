import React from "react";
import { useEffect } from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const CryonMap = () => {

    

    // const defaultState = {
    //     center: [55.751574, 37.573856],
    //     zoom: 5,
    // };

    const placemarkOptions = {

        iconLayout: 'default#image',
        iconImageHref: '/img/map-marker.svg', // URL of your custom icon
        iconImageSize: [30, 42], // size of the icon
        iconImageOffset: [-15, -42], // position of the icon
    };

    // const placemarkProperties = {
    //     balloonContentBody: "This is balloon loaded by the Yandex.Maps API module system",
    //     balloonOpen: true,
    // };

    const mapStyle = {
        width: '100%',
        height: '640px',
        borderRadius: '15px',
    };

    return (
        <section className="section map-section" id="contacts-link">
            <div className="contacts-block">
            
                {/* <div className="contats">
                    <h1>Контакты</h1>
                    <div className="address-phone">
                        <div className="address">
                            <p><strong>Адрес</strong></p>
                            <p>ул. Старопетровский пр. 11к1</p>
                            <p>метро Войковская</p>
                            <p><strong>Телефон</strong></p>
                            <p><a href="tel:+79956556564">+7 995 655-65-64</a></p>
                            <p><strong>Часы работы</strong></p>
                        <p>Пн.-Вс. с 10:00 - 22:00</p>
                        </div>

                    </div>


                </div> */}

                <YMaps
                    query={{
                        ns: "use-load-option",
                        load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
                    }}
                >
                    <Map
                        defaultState={{
                            center: [55.822973, 37.504334],
                            zoom: 9,
                            controls: ["zoomControl", "fullscreenControl"],
                            behaviors: ["multiTouch"]
                        }}
                        style={mapStyle}
                    >
                        <Placemark
                            defaultGeometry={[55.822973, 37.504334]}
                            properties={{
                                balloonContentBody:
                                    `<div class="addressonMap">
                                        <p><strong>Адрес</strong></p>
                                        <p>ул. Старопетровский пр. 8</p>
                                        <p>метро Войковская</p>
                                        <p><strong>Телефон</strong></p>
                                        <p><a href="tel:+79956556564">+7 995 655-65-64</a></p></p>
                                        <p><strong>Часы работы</strong></p>
                                        <p>Пн.-Вс. с 10:00 - 22:00</p>
                                    </div>`,
                                  balloonPanelMaxMapArea: 0,
                            }}
                            options={placemarkOptions}
                        />
                    </Map>
                </YMaps>

                {/* <div className="balloon">
                    <p>Старопетровский</p>
                    <p>Войковская</p>
                    <p>+8888888888</p>
                    <p>Пн.-Вс. с 10 до 22</p>
                </div> */}
            </div>
            
        </section>
    );
};

export default CryonMap;