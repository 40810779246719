import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Usp = () => {



  return (
    <section className="section usp-section">

      <div className="usp-header-container"><h2>Почему клиенты выбирают именно нас?</h2></div>
      <div className="usp-swiper-container">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          pagination={true}
          breakpoints={{
            // when window width is >= 640px
            320: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            // when window width is >= 768px
            370: {
              slidesPerView: 1,
              spaceBetween: 40
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 50
            },
            1920: {
              slidesPerView: 4,
              spaceBetween: 50
            }
          }}
          watchOverflow={true}
          navigation={{ nextEl: '#next', prevEl: '#prev' }}
          // scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide><div className="usp-item">
            <h3>Атмосфера</h3>
            <p>В наших салонах царит атмосфера уюта и комфорта</p>
          </div>
          </SwiperSlide>
          <SwiperSlide><div className="usp-item">
            <h3>Качество услуг</h3>
            <p>Мы работаем на качество, а не количество. Мы всегда позиционировали себя как семейный бутик, где комфортно проводить время</p>
          </div>
          </SwiperSlide>
          <SwiperSlide><div className="usp-item">
            <h3>Мастера</h3>
            <p>Наши сотрудники не оставят без внимания любое пожелание клиента</p>
          </div></SwiperSlide>
          <SwiperSlide><div className="usp-item">
            <h3>Оборудование</h3>
            <p>Своевременное облуживание оборудования</p>
          </div></SwiperSlide>
          <SwiperSlide><div className="usp-item">
            <h3>Программы лояльности</h3>
            <p>Гибкие программы лояльности для постоянных клиентов</p>
          </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="usp-item">
              <h3>Акции</h3>
              <p>Регулярные акции и специальные предложения</p>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* <div className="usp-next-prev-buttons-container"> */}
        <button id="prev"><img src="./img/Arrow-left.svg" /></button>
        <button id="next"><img src="./img/Arrow-right.svg" /></button>
        {/* </div> */}
      </div>


    </section>
  );
};

export default Usp;
