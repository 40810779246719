import React from "react";
import {useState, useEffect} from 'react';

const CatalogDetail = ({serviceId}) => {
    const [serviceDetails, setServiceDetails] = useState(null);
    console.log(serviceDetails);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://anvilis.ru/cryon/TESTS/get_service_info.php?serviceId=${serviceId}`);
                const data = await response.json();
                setServiceDetails(data);
            } catch (error) {
                console.error('Error fetching service details:', error);
            }
        };

        if (serviceId) {
            fetchData();
        }
    }, [serviceId]);

    if (!serviceDetails) {
        return (
            <div className="preloader">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="content services" id="sliding-div">
            <div className="services-info">
                <h3 id="service-title">{serviceDetails.title}</h3>
                <p id="service-description">{serviceDetails.description}</p>
                <ul id="service-results">
                    <h4>Результат процедуры</h4>
                    <li id="result-1">{serviceDetails.result1}</li>
                    <li id="result-2">{serviceDetails.result2}</li>
                    <li id="result-3">{serviceDetails.result3}</li>
                </ul>
                <div className="price-and-link_container">
                    <div><a href={`https://n1007076.yclients.com/company/514790/create-record?o=m-1s${serviceId}`}
                            className="cta" id="cta_url" target="_blank">Онлайн запись</a></div>
                    <div className="price-container">
                        {serviceDetails.price_min == 0 ? (
                            <span id="service-price">По запросу</span>
                        ) : (
                            <span id="service-price">{serviceDetails.price_min} ₽</span>
                        )}
                    </div>
                </div>
             
                {/* <a href={`/${serviceDetails.linkName}`} className="detail_page_url" id="service-url" target="_blank">О процедуре</a> */}
                {serviceDetails.linkName ? (
    <a href={`/${serviceDetails.linkName}`} className="detail_page_url" id="service-url" target="_blank">О процедуре</a>
  ) : null}
            </div>
            <div className="services-image">
                <img src={process.env.PUBLIC_URL + serviceDetails.imageUrl} id="service-image"/>
            </div>
        </div>
    );
};

export default CatalogDetail;