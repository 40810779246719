import React from "react";

const Cta = () => {
    return (
        <section class="cta_breaker">
            <div class="cta_breaker-content">
            <div class="cta_breaker-content-text">
                <h2>Акция - знакомство с мастером</h2>
                <p>Скидка 1 000р на первое посещение:  LPG-массж тела, Сферотерапия, AnchorFree и EMSCulpt!</p>
            </div>
            <a href="https://n1007076.yclients.com/company/514790/" class="cta_white">Записаться онлайн</a>
            </div>
        </section>
    );
};

export default Cta;