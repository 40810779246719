import React from "react";
import { useState, useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';

const NavigationDesk = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 768);
    });
  }, []);

  return (
    <>
     {isMobile ? (
        <>
        <nav className="navigation">
          <div className="logoDiv">
              <img src="/img/logo.svg" />
            </div>
          <div className="burger" onClick={toggleNav}>
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
              <path d="M7 42V37.3333H49V42H7ZM7 30.3333V25.6667H49V30.3333H7ZM7 18.6667V14H49V18.6667H7Z" fill="white"/>
          </svg>
            </div>
          <div id="myNav" className="overlay" style={{width: isNavVisible ? "100%" : "0%"}}>

              <a href="javascript:void(0)" className="closebtn" onClick={toggleNav}>&times;</a>

              <div className="overlay-content">
                  <a href="#about-link" onClick={toggleNav}>О нас</a>
                  <a href="#all_services" onClick={toggleNav}>Услуги и цены</a>
                  <a href="#masters-link" onClick={toggleNav}>Наши мастера</a>
                  {/* <a href="#tools-link" onClick={toggleNav}>Оборудование</a> */}
                  <a href="#reviews-link" onClick={toggleNav}>Отзывы</a>
                  <a href="#contacts-link" onClick={toggleNav}>Адреса студий</a>
              </div>

          </div>
        </nav>
        </>
      ) : (
        // Desktop navigation
        <nav className="navigation">
          <div className="logoDiv">
            <img src="/img/logo.svg" />
          </div>
          <div className="links">
          <Link smooth to="/#about-link">О наc</Link>
          <Link smooth to= "/#all_services">Услуги и цены</Link>
            <a href="#masters-link">Наши мастера</a>
            {/* <a href="#tools-link">Оборудование</a> */}
            <a href="#reviews-link">Отзывы</a>
            <a href="#contacts-link">Адреса студий</a>
            <a href="https://n1007076.yclients.com/company/514790/" className="cta">Записаться онлайн</a>
          </div>
      </nav>
      )}
   </>
  );
}

export default NavigationDesk;