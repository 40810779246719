import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';





const About = () => {

    return (
        <section className="section fullscreen light-gradient about-section" id="about-link">

            {/* <img className="background-absolute-img header_img" src="./img/about_us_header.svg" /> */}

        
            <div className="about-us-content">
                <div className="about-us-text">
                    <h2 id="about">О нас</h2>
                    <p>Студия коррекции фигуры "Крион" - ваш путь к идеальной форме!</p>
                    <p>Наши специалисты предлагают широкий спектр инновационных процедур и технологий для достижения желаемых результатов и уверенности в себе.</p>
                    <p>
                        Мы предлагаем гибкие абонементные программы, чтобы сделать наши услуги доступными и приятными для каждого клиента.
                    </p>
                    <p>
                        В «Крион» мы стремимся помочь вам выглядеть и чувствовать себя лучше, достигать ваших целей, а самое главное – получить удовольствие от процесса!.
                    </p>
                    <a href="https://n1007076.yclients.com/company/514790/" className="cta">Записаться онлайн</a>
                </div>

            </div>

            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                spaceBetween={0}
                slidesPerView={1}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                effect="coverflow"
                loop={true}
                watchOverflow={false}
                speed={2000}

                breakpoints={{
                    // when window width is >= 640px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    // when window width is >= 768px
                    370: {
                        slidesPerView: 1,
                        spaceBetween: 40
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 50
                    },
                    1920: {
                        slidesPerView: 1,
                        spaceBetween: 50
                    }
                }}

                watchOverflow={true}
                // navigation={{ nextEl: '#next', prevEl: '#prev' }}
                pagination={true}

                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
                <SwiperSlide>
                    <div className="about-item">
                        <img src="./img/cube.png" alt="about us" />
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <div className="about-item">
                        <img src="./img/image8.jpeg" alt="about us" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="about-item">
                        <img src="./img/image8.jpeg" alt="about us" />
                    </div>
                </SwiperSlide > */}
            </Swiper >
            
        </section >
    )
};

export default About;