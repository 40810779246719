import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Team = () => {
  return (
    <section className="light-gradient team-section">
      <div class="image-with-h2 team-header">
        <img class="header-img" src="./img/masters-header.svg" />
        <h2 id="masters-link">Мастера</h2>
      </div>
      <div className="team-swiper-container">
        <Swiper
          // other props
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          effect={'coverflow'}
          watchOverflow={true}
          navigation={{ nextEl: '#next', prevEl: '#prev' }}
          initialSlide={1}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          centeredSlides={true}
          slidesPerView={'3'}
          breakpoints={{
            // when window width is >= 640px
            320: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            // when window width is >= 768px
            370: {
              slidesPerView: 1,
              spaceBetween: 40
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 50
            },
            1920: {
              slidesPerView: 3,
              spaceBetween: 25
            }
          }}
        >
          <SwiperSlide>
            <div className="carousel-item">
              <img src="/img/komanda/pr-11.png" />
              <div className="master-description glass">
                <h3>Виктория</h3>
                <p>Основа моего искусства - гармония и красота в каждой ноте жизни. Моя страсть к путешествиям и чтению книг о психологии помогают мне находить ключи к гармонии между душой, телом и клиентами. Мои руки - преображают ваше тело в полотно изящества и расслабления. И да – я юрист по образованию, что не мешает мне быть эмпатом :)</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="carousel-item">
              <img src="/img/komanda/pr-22.png" />
              <div className="master-description glass">
                <h3>Дина</h3>
                <p>Личный опыт привел меня в сферу косметологии, где я использую обширный опыт и набор методов аппаратной косметологии и моделирования тела/лица. Обучение и опыт работы с такими техниками, как LPG-массаж, миостимуляция и лазерный липолиз, позволяют достигать эффективных результатов для наших клиентов. Вне работы я наслаждаюсь хобби, такими как моделирование одежды и выращивание растений, и моя философия заключается в том, что без любви все лишено смысла.</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="carousel-item">
              <img src="/img/komanda/pr-33.png" />
              <div className="master-description glass">
                <h3>Ксения</h3>
                <p>Я стараюсь помогать людям почувствовать себя уверенно и привлекательно. Мои увлечения включают (сюрприз) аппаратная косметология, изготовление свечей, спорт и изучение новых уходовых процедур для волос. Моя любимая процедура – LPG-массаж, я просто не могу представить день без него. В свободное время я занимаюсь изготовлением свечей, походами по магазинам и изучением новых уходовых процедур для волос, не забывая о важности защиты кожи от солнечных лучей и правильном питании.</p>
              </div>
            </div>
          </SwiperSlide>
        <SwiperSlide>
                  <div className="carousel-item">
                    <img src="/img/komanda/liya.png" />
                    <div className="master-description glass">
                      <h3>Лия</h3>
                      <p>Лия получила высшее медицинское образование, окончив лечебный факультет медицинского университета в Уфе в 2010 году. Она обладает более чем десятилетним опытом работы в ручных методиках массажа и около пяти лет занимается аппаратными методиками.
                      Лия особенно ценит в своей профессии возможность влиять на физический облик своих клиентов, корректировать недостатки фигуры и делать людей счастливее. С ней вы всегда можете быть уверены в профессиональном и заботливом подходе!</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="carousel-item">
                    <img src="/img/komanda/olga.png" />
                    <div className="master-description glass">
                      <h3>Ольга</h3>
                      <p>Ольга нашла своё призвание в косметологии и уже 3 года работает в этой сфере, начав с обычного LPG-массажа.<br/>
                      Косметология для Ольги — это больше, чем просто работа. Ей нравится видеть, как клиенты преображаются на её глазах. Эта сфера позволяет ей постоянно развиваться, приобретать новые знания и навыки, что приносит ей огромное удовольствие.
                      <br/>С Ольгой вы можете быть уверены в высоком уровне профессионализма и искреннем желании помочь вам выглядеть и чувствовать себя лучше.</p>
                    </div>
                  </div>
                </SwiperSlide>

        </Swiper>
        <div className="team-next-prev-buttons">
        <button id="prev"><img src="./img/Arrow-left.svg" /></button>
        <button id="next"><img src="./img/Arrow-right.svg" /></button>
        </div>
      </div>
    </section >
  );
};

export default Team;