import React from "react";
import CatalogSer from "./CatalogSer";
import {useState, useEffect} from "react";

const CatalogCat = ({category, onServiceClick, setActiveId, activeId}) => {
    
    const [isUnfolded, setIsUnfolded] = useState(false);

    const unfoldCategory = () => {
        // Изменяем состояние при каждом клике
        setIsUnfolded(!isUnfolded);
    };

    useEffect(() => {
        console.log(isUnfolded); // This will log the new value after the state update
    }, [isUnfolded]); // This tells React to call the effect when `isUnfolded` changes

    return (
        <div className="catalog-category">
            <div className='category-title-container' onClick={unfoldCategory}>
                <h3 className='category_header'>{category.title}</h3>
                <span className='unfold' style={{transform: isUnfolded ? 'rotate(45deg)' : 'rotate(0deg)'}}>+</span>
            </div>
            <div className={isUnfolded ? 'category-container__unfolded' : 'category-container__folded'}>
                <ul className='services_of_category'>
                    {
                        category.services.map((service) => {
                            return (
                                <CatalogSer service={service} activeId={activeId} setActiveId={setActiveId}
                                            onServiceClick={onServiceClick}/>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );

};

export default CatalogCat; 