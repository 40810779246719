import React, {useState} from "react";
import CatalogDetail from "./CatalogDetail";
import CatalogList from "./CatalogList";

const Catalog = ({categories}) => {
    const [selectedServiceId, setSelectedServiceId] = useState(7916840);

    return (
        <section id={'all_services'}>
            <div className="image-with-h2">
                <img className="header-img" src="./img/prices-bg.svg"/>
                <h2 id='priceList'>Услуги и цены</h2>
            </div>
            <div className="catalog-container">
                <CatalogList categories={categories} onServiceClick={setSelectedServiceId}/>
                <CatalogDetail serviceId={selectedServiceId}/>
            </div>
        </section>
    );
};

export default Catalog;