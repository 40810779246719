import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Actions = () => {
    return (
<section className="section actions-section">
<img className="percent" src="./img/percent.png" />
<Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={3}
        pagination={true}
        breakpoints={{
          // when window width is >= 640px
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 768px
          370: {
            slidesPerView: 1,
            spaceBetween: 40
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3,
            spaceBetween: 50
          },
          1368: {
            slidesPerView: 3,
            spaceBetween: 50
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 50
          }
        }}
        watchOverflow={true}
        navigation={{ nextEl: '#next', prevEl: '#prev' }}
        // pagination={{
        //   el: '.mySwiper-pagination',
        //   clickable: true,
        //   renderBullet: (index, className) => {
        //     return '<span class="' + className + '" style="background-color: #9F009F;"></span>';
        //   }
        // }}
        // scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        <SwiperSlide>
        <div className="action item">
            <h4>Реферальная программа</h4>
            <img className="action_icon" src="/img/actions/10-percent.png"/>
            <p className="action_descr">Для подруги – <strong>1000р.</strong>
            <br/>Для вас – <strong>10% кэшбэк</strong>
            </p>
        </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="action item">
           <h4>Гибкие абонементные программы</h4>
           <img className="action_icon" src="/img/actions/card.png"/>
           <p className="action_descr">Глубина скидки зависит от количества процедур в абонементе. 
               <br/><strong>Больше процедур – выше скидка.</strong>
               </p>
        </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="action item">
            <h4>Сезонные скидки на абонементы и сеты</h4>
            <img className="action_icon" src="/img/actions/seasonal.png"/>
            <p className="action_descr">Уточняйте наличие и условия у администратора</p>
        </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="action item">
            <h4>Сферотерапия для тела</h4>
            <img className="action_icon" src="/img/actions/1500.png"/>
            <p className="action_descr">Скидка 1500р на первый сеанс «Эндосфера тела»</p>
        </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="action item">
            <h4>Липосоникс</h4>
            <img className="action_icon" src="/img/actions/10-visit.png"/>
            <p className="action_descr">Каждый десятый квадрат – в подарок!</p>
        </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="action item">
            <h4>Мы любим отзывы</h4>
            <img className="action_icon" src="/img/actions/review.png"/>
            <p className="action_descr">Скидка 500р за предоставление отзыва на Яндекс Картах и 2GIS</p>
        </div>
        </SwiperSlide>
        
        </Swiper>
        <button id="prev"><img src="./img/Arrow-left.svg" /></button>
        <button id="next"><img src="./img/Arrow-right.svg" /></button>
        
        </section>
    );
};


export default Actions;