import React from "react";
const MainBanner = () => {
    return (
        <>
            <section className="section main fullscreen padding-for-main-banner">
                <div className="content">
                    <div className="left-part big-logo-mob">
                        <img className="banner-logo" src={"/img/big-banner-logo.svg"} />
                        <div className="action-block">
                            <a href="https://n1007076.yclients.com/company/514790/" className="cta">Записаться онлайн</a>
                        </div>
                    </div>
                    <div className="left-part">
                        <img className="banner-image" src={"/img/woman-1.png"} />
                    </div>
                </div>
            </section>
        </>
    )
};

export default MainBanner;