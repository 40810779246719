import React, { useEffect, useState } from "react";
import CatalogCat from "./CatalogCat";
import CatalogListMob from "./CatalogListMob";

const CatalogList = ({ categories, onServiceClick }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [activeId, setActiveId] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        setIsMobile(window.innerWidth < 768); // Set initial value based on screen width
        window.addEventListener("resize", handleResize); // Update value on resize

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up event listener
        };
    }, []);


    return (
        <>
            <div className="catalog-navigation" id="catNav">
                {isMobile ? (
                    <CatalogListMob categories={categories} onServiceClick={onServiceClick} />
                ) : (
                    categories.map((serviceCategory) => (
                        <>
                            <CatalogCat
                                category={serviceCategory}
                                onServiceClick={onServiceClick}
                                setActiveId={setActiveId}
                                activeId={activeId}
                            />
                            <hr />
                        </>
                    ))
                )}
            </div>
        </>
    );
};

export default CatalogList;