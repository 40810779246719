import NavigationDesk from './NavigationDesk';
import MainBanner from './MainBanner';
import About from './About';
import Usp from './Usp';
import Catalog from './Catalog/Catalog';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Actions from './Actions';
import Team from './Team';
import Cta from './Cta';
import Tools from './Tools';
import Reviews from './Reviews';
import CryonMap from './Map';
import Footer from './Footer';

function Main() {
  const [categories, setCategories] = useState([]);
  const [isMapRendered, setIsMapRendered] = useState(false);

  useEffect(() => {
    // Функция для получения данных из внешнего источника
    const fetchData = async () => {
      try {
        const response = await fetch('https://anvilis.ru/cryon/TESTS/get_list_of_categories.php');
        const data = await response.json();
        setCategories(data); // Обновление состояния с полученными данными
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Вызов функции для получения данных при монтировании компонента

   
  }, []); // Пустой массив зависимостей гарантирует, что эффект выполняется только один раз при монтировании компонента



  return (
    <>
      <NavigationDesk />
      <MainBanner />
      <hr class="border-gradient"></hr>
      <About />
      <Usp />
      <hr className="border-gradient" />
      <Catalog categories={categories} />
      <Actions />
      <Team />
      <Cta />
      {/* <Tools /> */}
      <Reviews />
      <CryonMap />
      <Footer />
    </>
  );
}

export default Main;